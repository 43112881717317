/**
 * Array con los inputs a mapear en el componente CodeInput
 * Esto es con la finalidad de poder manejar con facilidad las id's y las classNames de cada uno,
 * en especial los bordes (que hacen que luzcan como un todo)
 */
export const inputsToMap = [
	{
		border: 'border-l border-y rounded-l-lg',
		id: 'input1',
	},
	{
		border: 'border-y',
		id: 'input2',
	},
	{
		border: 'border-y',
		id: 'input3',
	},
	{
		border: 'border-y',
		id: 'input4',
	},
	{
		border: 'border-y',
		id: 'input5',
	},
	{
		border: 'border-r border-y rounded-r-lg',
		id: 'input6',
	},
]