import { documentType, gender, pronouns } from '@umahealth/entities'

export const pronounsOptions: {
	label: string,
	value: pronouns,
}[] = [
	{
		label: 'Él',
		value: 'Él',
	},
	{
		label: 'Ella',
		value: 'Ella',
	},
	{
		label: 'Elle',
		value: 'Elle',
	},
]

export const sexOptions: {
	label: string,
	value: gender,
}[] = [
	{
		label: 'Masculino',
		value: 'M',
	},
	{
		label: 'Femenino',
		value: 'F',
	},
	{
		label: 'No binarie',
		value: 'X',
	},
]

export const dniOptions: {
	label: string,
	value: documentType
}[] = [
	{
		label: 'DNI',
		value: 'DNI',
	},
	{
		label: 'Pasaporte',
		value: 'Pasaporte',
	},
	{
		label: 'L.C',
		value: 'LC',
	},
	{
		label: 'L.E',
		value: 'LE',
	},
	{
		label: 'C.I',
		value: 'CI',
	},
]