import React from 'react'
import UMA_logo from '@/assets/logoUma.png'
import IOMA_logo_powered from '@/assets/logos/IOMA_UMA.png'
import IOMA_logo from '@/assets/IOMA/IOMA_LOGO.svg'
import FARMATODO_logo from '@/assets/FARMATODO/CUIDAMOS_TU_SALUD.png'
import { IClientState, LogoNames } from '@/OldRouter/Providers/ProviderClient'
import { StaticImageData } from 'next/image'
import { IImage } from '@/reusableComponents/IImage'
import { Image } from '@umahealth/occipital'

type ILogo = Omit<IImage, 'src'|'alt'>

type ILogoApp = {
	'IOMA_LOGO'?: ILogo,
	'UMA_LOGO'?: ILogo,
	'FARMATODO_LOGO'?:ILogo,
	powered?: boolean
	/* Pone el logo de ÜMA aún si no esta seguro si es UMA o IOMA, ideal para rutas públicas*/
	force?: boolean 
	client?: IClientState|null
}
/** Logo de la empresa, revisa los datos de clientState, en caso de que no este cargada 
 * la información de que empresa mostrar, no muestra nada salvo tenga force = true.
 * Podes pasarle el tamaño de la imagen.
 * @example
 * <Logo 
 	IOMA_LOGO={{
							width: '165.12px',
							height: '75px',
						}}
						UMA_LOGO={{
							width:'165.12px',
							height: 'auto'
						}} powered/>
*/
function Logo( {client,...options} : ILogoApp){
	
	/** El tipo sirve para asegurarse que si tenemos un nuevo cliente salte error de TS
	 * Para añadir el nuevo logo de cliente
	 */
	const LogoPaths : Record<IClientState['logo_name']|'IOMA_POWERED_LOGO', StaticImageData> = {
		'UMA_LOGO' : UMA_logo,
		'IOMA_LOGO' : IOMA_logo,
		'IOMA_POWERED_LOGO' : IOMA_logo_powered,
		'FARMATODO_LOGO': FARMATODO_logo
	}

	const defaultLogo = options.force === true? 'UMA_LOGO' : undefined

	const logo : LogoNames|undefined = client?.logo_name?? defaultLogo

	if (!logo) {
		return null
	}

	/** Como options es una subclase de IImage excluyendo alt y src, podemos pasar así las opciones
	 * sin temer que haya un error
	 */
	return <Image 
		data-cy={`logo-${client?.client}`}
		src={LogoPaths[logo === 'IOMA_LOGO' && options.powered ? 'IOMA_POWERED_LOGO' : logo]} 
		alt='' 
		width={options[logo]?.width} 
		height={options[logo]?.height}
		className={`${options[logo]?.className}`}
		priority={false}
	/>
}

export default Logo