import { IShortcut } from '@umahealth/entities'
import posthog from 'posthog-js'

/**
 * Interfaz para las propiedades de los eventos de la página principal.
 */
interface HomeEventProperties {
	slide?: number | string
	modules?: IShortcut
	link?: string
	section?: string
}

/**
 * Registra un evento genérico en la página principal.
 * 
 * @param {string} action - La acción que se está rastreando.
 * @param {HomeEventProperties} properties - Las propiedades del evento.
 * 
 * @returns {void}
 */
function trackEventHome(action: string, properties: HomeEventProperties): void {
	posthog.capture(`home_${action}`, properties)
}

/**
 * Registra un evento de interacción con el deslizador de la página principal.
 * 
 * @param {number | string} slide - El identificador de la diapositiva.
 * 
 * @returns {void}
 */
export function trackEventHomeSlider(slide: number | string): void {
	trackEventHome('slider', { slide })
}

/**
 * Registra un evento de interacción con los módulos en la página principal.
 * 
 * @param {string[]} modules - Un arreglo con los nombres o identificadores de los módulos.
 * 
 * @returns {void}
 */
export function trackEventsHomeModules(modules: IShortcut): void {
	trackEventHome('modules', { modules })
}

/**
 * Registra un evento de clic en el enlace de redirección al doctor en línea.
 * 
 * @param {string} link - La URL de la página del doctor en línea.
 * 
 * @returns {void}
 */
export function trackEventOnlineDoctorRedirect(link: string): void {
	trackEventHome('online_doctor_redirect', { link })
}

/**
 * Registra un evento de clic en el enlace de redirección al especialista.
 * 
 * @param {string} link - La URL de la página del especialista.
 * 
 * @returns {void}
 */
export function trackEventSpecialistRedirect(link: string): void {
	trackEventHome('specialist_redirect', { link })
}

/**
 * Registra un evento de interacción con el botón de arrepentimiento.
 * 
 * @returns {void}
 */
export function trackEventRegretButton(): void {
	trackEventHome('regret_button', {})
}

/**
 * Registra un evento de interacción con una sección del historial.
 * 
 * @param {string} section - El identificador o nombre de la sección del historial.
 * 
 * @returns {void}
 */
export function trackEventHistorySection(section: string): void {
	trackEventHome('history_section', { section })
}

/**
 * Registra un evento de interacción con el perfil.
 * 
 * @returns {void}
 */
export function trackEventProfile(): void {
	trackEventHome('profile', {})
}

/**
 * Registra un evento de interacción con el botón de notificaciones.
 * 
 * @returns {void}
 */
export function trackEventNotificationsButton(): void {
	trackEventHome('notifications_button', {})
}
