export const dniConflictErrorMsg = 'El documento está asociado a una cuenta existente'
export const dniLengthErrorMsg = 'El DNI debe tener entre 7 y 10 dígitos'
export const dniRegexErrorMsg = 'Solo se permiten números'

export const passportLengthErrorMsg = 'El Pasaporte debe tener entre 8 y 10 dígitos'
export const passportCharacterErrorMsg = 'Solo se permiten letras mayúsculas y números'

export const wsConflictErrorMsg = 'El teléfono está asociado a una cuenta existente'
export const wsCharacterErrorMsg = 'Solo se permiten números'
export const wsLengthErrorMsg =
	'Debe tener entre 9 y 12 dígitos, incluyendo prefijo internacional y código de área'
export const wsPatternErrorMsg =
	'Su número de teléfono no respeta el siguiente formato: prefijo internacional(+54) código de área(11) número de teléfono(sin 15)'

export const nameCharacterErrorMsg = 'Solo se permiten letras mayúsculas y minúsculas'
export const nameLengthErrorMsg = 'Debe tener entre 2 y 74 caracteres'

export const dobInvalidErrorMsg = 'La fecha no puede ser posterior a la actual'

