import { auth } from '@/config/DBConnection'
import { send_phone_code } from '@/config/endpoints'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'

export interface ISendCodeResponse {
	sent: boolean,
	error: boolean,
}

type Options = Omit<UseMutationOptions<ISendCodeResponse, AxiosError, string>, 'mutationKey' | 'mutationFn'>

export const useSendPhoneCode = (options?: Options) => {
	return useMutation<ISendCodeResponse, AxiosError, string>({
		mutationKey: ['MegalithQuery', 'SendPhoneCode'],
		mutationFn: async (phoneNumber: string) => {
			const firebaseToken = await getFirebaseIdToken()
			// borrar
			const currentUser = auth?.currentUser

			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${firebaseToken}`,
				uid: currentUser?.uid
			}

			const data = {
				phoneNumber,
			}

			const response = await axios.post<ISendCodeResponse>(send_phone_code, data, { headers })
			return response.data
		},
		...options,
	})
}