import { auth } from '@/config/DBConnection'
import { validate_phone_code } from '@/config/endpoints'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'

export interface IValidatePhoneData {
	currentPhone: string,
	code: number,
}

export interface IValidatePhoneResponse {
	validated: boolean,
	error: boolean,
}

type Options = Omit<UseMutationOptions<IValidatePhoneResponse, AxiosError, IValidatePhoneData>, 'mutationKey' | 'mutationFn'>

export const useValidatePhone = (options?: Options) => {
	return useMutation<IValidatePhoneResponse, AxiosError, IValidatePhoneData>({
		mutationKey: ['MegalithQuery', 'ValidatePhone'],
		mutationFn: async (data: IValidatePhoneData) => {
			const firebaseToken = await getFirebaseIdToken()
			// borrar
			const currentUser = auth?.currentUser

			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${firebaseToken}`,
				uid: currentUser?.uid
			}

			const response = await axios.post<IValidatePhoneResponse>(validate_phone_code, data, { headers })
			return response.data
		},
		...options,
	})
}