import { RefObject } from 'react'
import { InputsRefs } from './CodeInput'

/**
 * Itera un objeto de inputRefs
 * @param inputsRefs objeto con todas las referencias a los inputs { input1: ref, input2: ref, ... }
 * @returns el ultimo input que tiene la prop readOnly=false
 */
export const getLastInputEnabled = (inputsRefs: InputsRefs) => {
	let lastInputEnabled: RefObject<HTMLInputElement> | null = null
	for (const id in inputsRefs) {
		const input = inputsRefs[id]
		if (!input.current.readOnly) {
			lastInputEnabled = input
		}
	}
	return lastInputEnabled
}