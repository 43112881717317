import posthog from 'posthog-js'

function trackHeaderEvent (action: string, properties?: Record<string, string | number>) {
	posthog.capture(`header_${action}`, properties)
}

export function trackHeaderEventBackButton (view: string) {
	trackHeaderEvent('back_button', {
		view,
	})
}