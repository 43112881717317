export const countriesListWithLabel = [
	{ label: 'Afganistán', value: 'AF' },
	{ label: 'Albania', value: 'AL' },
	{ label: 'Argelia', value: 'DZ' },
	{ label: 'Andorra', value: 'AD' },
	{ label: 'Angola', value: 'AO' },
	{ label: 'Antigua y Barbuda', value: 'AG' },
	{ label: 'Argentina', value: 'AR' },
	{ label: 'Armenia', value: 'AM' },
	{ label: 'Australia', value: 'AU' },
	{ label: 'Austria', value: 'AT' },
	{ label: 'Azerbaiyán', value: 'AZ' },
	{ label: 'Bahamas', value: 'BS' },
	{ label: 'Bahréin', value: 'BH' },
	{ label: 'Bangladesh', value: 'BD' },
	{ label: 'Barbados', value: 'BB' },
	{ label: 'Bielorrusia', value: 'BY' },
	{ label: 'Bélgica', value: 'BE' },
	{ label: 'Belice', value: 'BZ' },
	{ label: 'Benin', value: 'BJ' },
	{ label: 'Bhután', value: 'BT' },
	{ label: 'Bolivia', value: 'BO' },
	{ label: 'Bosnia y Herzegovina', value: 'BA' },
	{ label: 'Botswana', value: 'BW' },
	{ label: 'Brasil', value: 'BR' },
	{ label: 'Brunei Darussalam', value: 'BN' },
	{ label: 'Bulgaria', value: 'BG' },
	{ label: 'Burkina Faso', value: 'BF' },
	{ label: 'Burundi', value: 'BI' },
	{ label: 'Cabo Verde', value: 'CV' },
	{ label: 'Camboya', value: 'KH' },
	{ label: 'Camerún', value: 'CM' },
	{ label: 'Canadá', value: 'CA' },
	{ label: 'República Centroafricana', value: 'CF' },
	{ label: 'Chad', value: 'TD' },
	{ label: 'Chile', value: 'CL' },
	{ label: 'China', value: 'CN' },
	{ label: 'Colombia', value: 'CO' },
	{ label: 'Comoras', value: 'KM' },
	{ label: 'Congo (Brazzaville)', value: 'CG' },
	{ label: 'Congo (Kinshasa)', value: 'CD' },
	{ label: 'Costa Rica', value: 'CR' },
	{ label: 'Croacia', value: 'HR' },
	{ label: 'Cuba', value: 'CU' },
	{ label: 'Chipre', value: 'CY' },
	{ label: 'República Checa', value: 'CZ' },
	{ label: 'Dinamarca', value: 'DK' },
	{ label: 'Djibouti', value: 'DJ' },
	{ label: 'Dominica', value: 'DM' },
	{ label: 'República Dominicana', value: 'DO' },
	{ label: 'Ecuador', value: 'EC' },
	{ label: 'Egipto', value: 'EG' },
	{ label: 'El Salvador', value: 'SV' },
	{ label: 'Guinea Ecuatorial', value: 'GQ' },
	{ label: 'Eritrea', value: 'ER' },
	{ label: 'Estonia', value: 'EE' },
	{ label: 'Eswatini', value: 'SZ' },
	{ label: 'Etiopía', value: 'ET' },
	{ label: 'Fiyi', value: 'FJ' },
	{ label: 'Finlandia', value: 'FI' },
	{ label: 'Francia', value: 'FR' },
	{ label: 'Gabón', value: 'GA' },
	{ label: 'Gambia', value: 'GM' },
	{ label: 'Georgia', value: 'GE' },
	{ label: 'Alemania', value: 'DE' },
	{ label: 'Ghana', value: 'GH' },
	{ label: 'Grecia', value: 'GR' },
	{ label: 'Granada', value: 'GD' },
	{ label: 'Guatemala', value: 'GT' },
	{ label: 'Guinea', value: 'GN' },
	{ label: 'Guinea-Bissau', value: 'GW' },
	{ label: 'Guyana', value: 'GY' },
	{ label: 'Haití', value: 'HT' },
	{ label: 'Santa Sede (Ciudad del Vaticano)', value: 'VA' },
	{ label: 'Honduras', value: 'HN' },
	{ label: 'Hungría', value: 'HU' },
	{ label: 'Islandia', value: 'IS' },
	{ label: 'India', value: 'IN' },
	{ label: 'Indonesia', value: 'ID' },
	{ label: 'Irán', value: 'IR' },
	{ label: 'Iraq', value: 'IQ' },
	{ label: 'Irlanda', value: 'IE' },
	{ label: 'Israel', value: 'IL' },
	{ label: 'Italia', value: 'IT' },
	{ label: 'Jamaica', value: 'JM' },
	{ label: 'Japón', value: 'JP' },
	{ label: 'Jordania', value: 'JO' },
	{ label: 'Kazajistán', value: 'KZ' },
	{ label: 'Kenia', value: 'KE' },
	{ label: 'Kiribati', value: 'KI' },
	{ label: 'Corea del Norte', value: 'KP' },
	{ label: 'Corea del Sur', value: 'KR' },
	{ label: 'Kuwait', value: 'KW' },
	{ label: 'Kirguistán', value: 'KG' },
	{ label: 'República Democrática Popular de Laos', value: 'LA' },
	{ label: 'Letonia', value: 'LV' },
	{ label: 'Líbano', value: 'LB' },
	{ label: 'Lesoto', value: 'LS' },
	{ label: 'Liberia', value: 'LR' },
	{ label: 'Libia', value: 'LY' },
	{ label: 'Liechtenstein', value: 'LI' },
	{ label: 'Lituania', value: 'LT' },
	{ label: 'Luxemburgo', value: 'LU' },
	{ label: 'Madagascar', value: 'MG' },
	{ label: 'Malaui', value: 'MW' },
	{ label: 'Malasia', value: 'MY' },
	{ label: 'Maldivas', value: 'MV' },
	{ label: 'Malí', value: 'ML' },
	{ label: 'Malta', value: 'MT' },
	{ label: 'Islas Marshall', value: 'MH' },
	{ label: 'Mauritania', value: 'MR' },
	{ label: 'Mauricio', value: 'MU' },
	{ label: 'México', value: 'MX' },
	{ label: 'Micronesia', value: 'FM' },
	{ label: 'Moldavia', value: 'MD' },
	{ label: 'Mónaco', value: 'MC' },
	{ label: 'Mongolia', value: 'MN' },
	{ label: 'Montenegro', value: 'ME' },
	{ label: 'Marruecos', value: 'MA' },
	{ label: 'Mozambique', value: 'MZ' },
	{ label: 'Myanmar (Birmania)', value: 'MM' },
	{ label: 'Namibia', value: 'NA' },
	{ label: 'Nauru', value: 'NR' },
	{ label: 'Nepal', value: 'NP' },
	{ label: 'Países Bajos', value: 'NL' },
	{ label: 'Nueva Zelanda', value: 'NZ' },
	{ label: 'Nicaragua', value: 'NI' },
	{ label: 'Níger', value: 'NE' },
	{ label: 'Nigeria', value: 'NG' },
	{ label: 'Macedonia del Norte', value: 'MK' },
	{ label: 'Noruega', value: 'NO' },
	{ label: 'Omán', value: 'OM' },
	{ label: 'Pakistán', value: 'PK' },
	{ label: 'Palaos', value: 'PW' },
	{ label: 'Palestina', value: 'PS' },
	{ label: 'Panamá', value: 'PA' },
	{ label: 'Papúa Nueva Guinea', value: 'PG' },
	{ label: 'Paraguay', value: 'PY' },
	{ label: 'Perú', value: 'PE' },
	{ label: 'Filipinas', value: 'PH' },
	{ label: 'Polonia', value: 'PL' },
	{ label: 'Portugal', value: 'PT' },
	{ label: 'Catar', value: 'QA' },
	{ label: 'Rumania', value: 'RO' },
	{ label: 'Rusia', value: 'RU' },
	{ label: 'Ruanda', value: 'RW' },
	{ label: 'San Cristóbal y Nieves', value: 'KN' },
	{ label: 'Santa Lucía', value: 'LC' },
	{ label: 'San Vicente y las Granadinas', value: 'VC' },
	{ label: 'Samoa', value: 'WS' },
	{ label: 'San Marino', value: 'SM' },
	{ label: 'Santo Tomé y Príncipe', value: 'ST' },
	{ label: 'Arabia Saudita', value: 'SA' },
	{ label: 'Senegal', value: 'SN' },
	{ label: 'Serbia', value: 'RS' },
	{ label: 'Seychelles', value: 'SC' },
	{ label: 'Sierra Leona', value: 'SL' },
	{ label: 'Singapur', value: 'SG' },
	{ label: 'Eslovaquia', value: 'SK' },
	{ label: 'Eslovenia', value: 'SI' },
	{ label: 'Islas Salomón', value: 'SB' },
	{ label: 'Somalia', value: 'SO' },
	{ label: 'Sudáfrica', value: 'ZA' },
	{ label: 'Sudán del Sur', value: 'SS' },
	{ label: 'España', value: 'ES' },
	{ label: 'Sri Lanka', value: 'LK' },
	{ label: 'Sudán', value: 'SD' },
	{ label: 'Surinam', value: 'SR' },
	{ label: 'Suecia', value: 'SE' },
	{ label: 'Suiza', value: 'CH' },
	{ label: 'Siria', value: 'SY' },
	{ label: 'Taiwán', value: 'TW' },
	{ label: 'Tayikistán', value: 'TJ' },
	{ label: 'Tanzania', value: 'TZ' },
	{ label: 'Tailandia', value: 'TH' },
	{ label: 'Timor Oriental', value: 'TL' },
	{ label: 'Togo', value: 'TG' },
	{ label: 'Tonga', value: 'TO' },
	{ label: 'Trinidad y Tobago', value: 'TT' },
	{ label: 'Túnez', value: 'TN' },
	{ label: 'Turquía', value: 'TR' },
	{ label: 'Turkmenistán', value: 'TM' },
	{ label: 'Tuvalu', value: 'TV' },
	{ label: 'Uganda', value: 'UG' },
	{ label: 'Ucrania', value: 'UA' },
	{ label: 'Emiratos Árabes Unidos', value: 'AE' },
	{ label: 'Reino Unido', value: 'GB' },
	{ label: 'Estados Unidos', value: 'US' },
	{ label: 'Uruguay', value: 'UY' },
	{ label: 'Uzbekistán', value: 'UZ' },
	{ label: 'Vanuatu', value: 'VU' },
	{ label: 'Venezuela', value: 'VE' },
	{ label: 'Vietnam', value: 'VN' },
	{ label: 'Yemen', value: 'YE' },
	{ label: 'Zambia', value: 'ZM' },
	{ label: 'Zimbabue', value: 'ZW' }
] as const