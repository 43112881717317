'use client'
import { useRouter } from 'next/navigation'
import { IHeader } from './IHeader'
import HeaderLogo from './Header_Logo'
import { IClientState } from '@/config/clients'
import { trackHeaderEventBackButton } from '@/events/headerEvents'

export default function LeftButton({ action, type, to, client, trackView }: Pick<IHeader, 'action'|'type'|'to'|'trackView'> & { client?: IClientState }){
	const router = useRouter()

	if (action){
		return <a
			onClick={action}
			alt-text='Inicio'
			className='cursor-pointer'
		>
			<HeaderLogo client={client} type={type}/>
		</a>
	}

	if (!to && type === 'back'){
		return 	<a
			onClick={() => router.back()}
			alt-text='Retroceder'
			className='cursor-pointer'
		>
			<HeaderLogo client={client} type={type}/>
		</a>
	}

	return 	<button
		alt-text='Volver'
		className='cursor-pointer'
		onClick={() => {
			if (trackView) {
				trackHeaderEventBackButton(trackView)
			}
			router.replace(to??'/')
		}}
	>
		<HeaderLogo client={client} type={type}/>
	</button>

}